.loaderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.loader {
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #cc7d06; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

.thinLoader {
  border: 2px solid #f3f3f3; /* Light grey */
  border-top: 2px solid #cc7d06; /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
