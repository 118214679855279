@font-face {
  font-family: "inter";
  src: local("Inter Black"), local("Inter-Blk"),
    url("Inter-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "inter";
  src: local("Inter ExtraBold"), local("Inter-ExtBld"),
    url("Inter-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "inter";
  src: local("Inter Bold"), local("Inter-Bld"),
    url("Inter-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "inter";
  src: local("Inter Sans Bold"), local("InterSans-Bld"),
    url("Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "inter";
  src: local("Inter Medium"), local("Inter-Med"),
    url("Inter-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "inter";
  src: local("Inter Regular"), local("Inter-Reg"),
    url("Inter-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "inter";
  src: local("Inter Light"), local("Inter-Lgt"),
    url("Inter-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
