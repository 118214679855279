.catalog {
  display: flex;
  flex-direction: column;
}

.subtitle {
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  color: #0a142f;
  margin-bottom: 15px;
}

.footer_link {
  color: #0a142f;
  opacity: 0.5;
  margin-bottom: 10px;
}

@media screen and (max-width: 1200px) {
  .catalog {
    display: none;
  }
}
@media screen and (max-width: 1200px) {
  .catalog {
    display: none;
  }
}
