.wrapper {
  display: flex;
  align-items: center;
}

.searchWrapper {
  display: flex;
  justify-content: center;
  // flex-direction: row;
  width: 100%;
  padding: 0 16px;
}

.clean {
  cursor: pointer;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  color: #bebec7;
  margin-right: 20px;
  padding: 2px 0;
}

.open_search {
  position: fixed;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.search {
  display: flex;
  align-items: center;
}
.search_box {
  position: relative;
  display: flex;
  align-items: center;
  width: 435px;
  height: 39px;
  border: 1px solid #bebec7;
  background-color: #fff;
  border-radius: 20px;
  padding: 0 14px;
  margin-right: 18px;
  z-index: 200;

  img {
    margin-right: 14px;
  }

  input {
    border: none;
    font-size: 16px;
    line-height: 19px;
    // color: #bebec7;
    background: none;
    width: 100%;

    &::placeholder {
      font-size: 16px;
      line-height: 19px;
      color: #bebec7;
    }
  }
}

.historyTitle {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-bottom: 10px;
}

.search_items {
  position: absolute;
  top: 48px;
  left: 0;
  width: 100%;
  max-height: 400px;
  padding: 12px;
  background: #fff;
  box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.04);
  border-radius: 20px;
  z-index: 200;
  overflow: auto;
}
.close_btn {
  border: none;
  cursor: pointer;
  background-color: transparent;
  padding: 5px;
}

.search_item {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 13px 0;

  &:hover {
    background-color: #f7f7f7;
  }

  &:not(:last-child) {
    border-bottom: 0.5px solid #f7f7f7;
  }
}
.search_img {
  width: 27px;
  height: 15px;
  margin-right: 5px;
}
.search_name {
  font-size: 12px;
  line-height: 15px;
  color: #8b8b8b;
}
@media screen and (max-width: 958px) {
  .search_box {
    width: 250px;

    input {
      width: 100%;
    }
  }
  .clean {
    margin-right: 10px;
  }
}
@media screen and (max-width: 768px) {
  .wrapper {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 200px;
    height: 10px;
    overflow: hidden;
    transition: all 4s ease;
    align-items: end;
    justify-content: start;
    background-color: #fff;
    flex-direction: column-reverse;
  }
  .open_search {
    width: 100vw;
    height: 100vh;
  }
  .search_box {
    flex-grow: 1;
    margin-right: 5px;
  }
  .search {
    width: 90%;
    justify-content: center;
    margin: 16px auto;
  }
  // .clean {
  //   // margin-right: 36px;
  //   // margin-top: 20px;
  // }
}
// @media screen and (max-width: 576px) {
//   .clean {
//     // margin-right: 20px;
//   }
// }
