.prev {
  position: absolute;
  top: 45%;
  left: 3%;

  &:disabled {
    svg {
      background-color: #c8c8d0;
      opacity: 0.6;
      // transform: matrix(-1, 0, 0, 1, 0, 0);
    }
  }
}

.next {
  top: 45%;
  right: 2%;
  position: absolute;

  &:disabled {
    svg {
      background-color: #c8c8d0;
      opacity: 0.6;
      // transform: matrix(-1, 0, 0, 1, 0, 0);
    }
  }
}
