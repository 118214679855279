.wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 100px 0 60px 0;
  width: 100%;
}

@media (max-width: 576px) {
  .wrapper {
    margin: 50px 0 60px 0;
  }
}
