@import "../assets/fonts/Inter/stylesheet.css";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap");
@import "reset.scss";

body {
  font-family: "inter", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.page_content {
  flex-grow: 1;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: #ffffff;
  outline: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  background-color: #dbdbdb;
  border-radius: 20px;
  border: 1px solid #fff {
    bottom: none;
  }
}
