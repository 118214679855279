.swiperPrevIcon {
  z-index: 1;
  padding: 5px;
  cursor: pointer;
  position: absolute;
  border-radius: 33px;
  background-color: #c8c8d0;
  width: 30px;
  height: 30px;
}
.swiperNextIcon {
  z-index: 1;
  padding: 5px;
  cursor: pointer;
  position: absolute;
  border-radius: 33px;
  background-color: #c8c8d0;
  width: 30px;
  height: 30px;
  transform: rotate(180deg);
  transform-origin: left;
}

.categoriesWrapper {
  position: relative;
  height: 100%;
  padding: 0 16px 0 16px;
}

.categoryWrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.category_box {
  cursor: pointer;
  width: 100%;
  max-width: 350px;
  height: 250px;
  border-radius: 4%;
  transition: transform 0.2s;
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    opacity: 0.6;
  }

  &:hover {
    transform: scale(0.95);
  }

  .categoty_text {
    position: absolute;
    left: 40px;
    bottom: 25px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #ffffff;
    text-shadow: 0 12px 4px rgba(0, 0, 0, 0.16);
    z-index: 1;
  }

  .boxImg {
    width: 100%;
    height: 100%;
    border-radius: 4%;
    object-fit: cover;
  }
}
